<template>
  <div class="back-button">
    <svg class="back-button__img" width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.5391 19.3223L14.8516 30.01L25.5487 40.7071" stroke="black" stroke-linecap="round"/>
      <path d="M15.3012 29.973L38.2422 29.9732" stroke="black" stroke-linecap="round"/>
    </svg>
    <p class="back-button__text">
      Back to all cases
    </p>
  </div>
</template>

<script>
export default {
  name: 'BackButton',
};
</script>

<style lang="scss" scoped>
  .back-button {
    display: flex;
    align-items: center;

    &__img {
      max-width: 100%;
      height: auto;
      margin-right: 20px;

      path {
        stroke: var(--text-primary-color)
      }
    }


    &__text {
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;
      letter-spacing: 0.04em;
    }
  }
</style>
